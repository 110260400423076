import React from 'react';

export const softwareVersions = [
  [[null]], // 0
  // 1
  [
    [null], // 1.0.0
    [null], // 1.1.0
    [null], // 1.2.0

    // 1.3
    [
      // 1.3.0
      null,
      // 1.3.1
      null,
      // 1.3.2
      null,
      // 1.3.3
      null,
      // 1.3.4
      null,
      // 1.3.5
      null,
      // 1.3.6
      null,
      // 1.3.7
      null,
      // 1.3.8
      null,
      // 1.3.9
      { date: 1686841200000 },
      // 1.3.10
      { date: 1687014000000 },
      // 1.3.11
      { date: 1687273200000 },
      // 1.3.12
      { date: 1687359600000 },
      // 1.3.13
      { date: 1687532400000 },
      // 1.3.14
      { date: 1687791600000 },
      // 1.3.15
      { date: 1688482800000 },
      // 1.3.16
      { date: 1688828400000 },
      // 1.3.17
      { date: 1691074800000 },
      // 1.3.18
      { date: 1691161200000 },
      // 1.3.19
      { date: 1694098800000 },
      // 1.3.20
      { date: 1695567600000 },
      // 1.3.21
      { date: 1695654000000 },
      // 1.3.22
      { date: 1696086000000 },
      // 1.3.23
      { date: 1696258800000 },
      // 1.3.24
      { date: 1696863600000 },
      // 1.3.25
      { date: 1697295600000 },
      // 1.3.26
      { date: 1697468400000 },
      // 1.3.27
      { date: 1697986800000 },
      // 1.3.28
      { date: 1707490800000 },
      // 1.3.29
      { date: 1707490800000 },
      // 1.3.30
      { date: 1708182000000 },
      // 1.3.31
      { date: 1708354800000 },
      // 1.3.32
      { date: 1708354800000 },
      // 1.3.33
      { date: 1708441200000 },
      // 1.3.34
      { date: 1709305200000 },
      // 1.3.35
      { date: 1709650800000 },
      // 1.3.36
      { date: 1711206000000 },
      // 1.3.37
      { date: 1711465200000 },
      // 1.3.38
      { date: 1713020400000 },
      // 1.3.39
      { date: 1713020400000 },
      // 1.3.40
      { date: 1713625200000 },
      // 1.3.41
      { date: 1714230000000 },
      // 1.3.42
      { date: 1714575600000 },
      // 1.3.43
      { date: 1715094000000 },
      // 1.3.44
      { date: 1715958000000 },
      // 1.3.45
      { date: 1715958000000 },
      // 1.3.46
      { date: 1716908400000 },
      // 1.3.47
      { date: 1717254000000 },
      // 1.3.48
      { date: 1717340400000 },
      // 1.3.49
      { date: 1717858800000 },
      // 1.3.50
      { date: 1717858800000 },
    ],

    // 1.4
    [
      // 1.3.0
      { date: 1719500400000 },
      // 1.3.1
      { date: 1719846000000 },
      // 1.3.2
      { date: 1720450800000 },
      // 1.3.3
      { date: 1721142000000 },
      // 1.3.4
      { date: 1722265200000 },
      // 1.3.5
      { date: 1722783600000 },
      // 1.3.6
      { date: 1723906800000 },
      // 1.3.7
      { date: 1723906800000 },
      // 1.3.8
      { date: 1724684400000 },
      // 1.3.9
      { date: 1725375600000 },
      // 1.3.10
      { date: 1725980400000 },
    ],

    // 1.5
    [
      // 1.5.0
      { date: 1726112331000 },
    ],
  ],
];
